@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap');
body{font-family: 'Jost', sans-serif;}

header{z-index:10}
#main{min-height:80vh}
.navbar-dark{background-color:#2b3035 !important}
.navbar-toggler{border:none}
.navbar-toggler:focus{box-shadow:none;border:1px solid #ccc}
.logo{height:50px}
.bg-light{background-color:#f5f5f5 !important}

.theme-switch{--size-of-icon:1.4rem;--slider-offset:.3rem;position:relative;width:3rem;height:2rem}
.theme-switch input{opacity:0;width:0;height:0}
.theme-switch .slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#dadada;transition:.4s;border-radius:30px}
.theme-switch .slider:before{position:absolute;content:"";height:var(--size-of-icon,1.4rem);width:var(--size-of-icon,1.4rem);border-radius:20px;left:var(--slider-offset,0.3rem);top:50%;transform:translateY(-50%);background:linear-gradient(40deg,#ff0080,#ff8c00 70%);transition:.4s}
.theme-switch input:checked + .slider{background-color:#404040}
.theme-switch input:checked + .slider:before{left:calc(100% - (var(--size-of-icon,1.4rem) + var(--slider-offset,0.3rem)));background:#303136;box-shadow:inset -3px -2px 5px -2px #8983f7,inset -10px -4px 0 0 #a3dafb}

.form-control{box-shadow:none !important}
#myText[style^="color: white"]::placeholder{color:#fff}
#form #btn-group .btn:hover{background:inherit; color:inherit}
#form .btn-info{color: #fff}

#faqAccordion.dark .accordion-button::after{filter:invert(1)}
#faqAccordion.dark,
#faqAccordion.dark .accordion-button.collapsed,
#faqAccordion.dark .accordion-item{background-color:#212529;color:#fff;border-color:#555}
#faqAccordion.dark .accordion-button:not(.collapsed){color:rgb(110, 168, 254);background-color:rgb(3, 22, 51);box-shadow:rgb(73, 80, 87) 0px -1px 0px 0px inset}

@keyframes spin{
from{transform:rotate(0deg)}
to{transform:rotate(360deg)}
}